export default [
    {
        title: 'Dashboards',
        icon: 'HomeIcon',
        route: 'home',
        resource: 'all',
        action: 'read',
    },
    {
        title: 'Accounts',
        route: 'account-page',
        icon: 'UsersIcon',
        resource: 'all',
        action: 'update',
        children: [
            {
                title: 'Users',
                route: 'list-user-page',
                resource: 'all',
                action: 'update',
            },
            {
                title: 'Admins',
                route: 'list-admin-page',
                resource: 'all',
                action: 'manage',
            },
        ],
    },
    {
        title: 'Domains',
        route: 'list-domain-page',
        icon: 'ServerIcon',
        resource: 'all',
        action: 'manage',
    },
    {
        title: 'Licenses',
        route: 'list-license-page',
        icon: 'FileTextIcon',
        resource: 'all',
        action: 'manage',
    },
    {
        title: 'Groups',
        route: 'list-group-page',
        icon: 'FolderIcon',
        resource: 'all',
        action: 'update',
    },
    {
        title: 'Logs Reset Password',
        route: 'list-log-page',
        icon: 'DatabaseIcon',
        resource: 'all',
        action: 'update',
    },
]
